import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Content, Row, Col, Bound } from "../components/layouts"


import PageIntro from "../components/pageIntro"
import BlogPostListing from "../components/BlogPostListing"
import MenuReset from "../components/MenuReset"

const BlogPage = ({ data }) => (
  <Layout solidHeader>
    <SEO title="Blog" />
    <MenuReset />

    <PageIntro>
      <h2>Blog</h2>
    </PageIntro>

    <Content>
      <Row>
        <Col>
          <Bound>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <BlogPostListing
                key={node.fields.slug}
                title={node.frontmatter.title}
                slug={node.fields.slug}
                description={node.excerpt}
              />
            ))}
          </Bound>
        </Col>
      </Row>
    </Content>
  </Layout>
)

export default BlogPage

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { slug: { glob: "/blog/*" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
